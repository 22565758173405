@import "../scss/mixins.scss";

.footer_Wrapper {
  padding: 1.5rem 0;

  .footer_topBorder {
    margin-top: 1.5rem;
  }

  .footer_box {
    .footer_Left {
      &.marginLeft {
        padding-left: 1.5rem;
        margin-right: 50px;

        .DownloadApp {
          display: grid;
        }
      }

      .footer_socialIcons {
        margin: 1.5rem 0;
        margin-left: -6px;
      }

      .footer_appLinks {
        margin-top: 1rem;
        width: 100%;
        max-width: 130px;
        height: auto;
      }
    }

    .footer_Right {
      .marginBox {
        margin-left: 0;
        margin-right: 108 px;

        &.marginDiff {
          padding-left: 1.5rem;
        }

        &.lastDiffe {
          margin-right: 190px;
        }
      }

      .footer_Linkh6 {
        text-transform: uppercase;
      }

      .footer_divider {
        margin: 1rem 0px 0px;
      }

      .footer_Links {
        a {
          text-decoration: none;
          width: fit-content;
        }
        .footer_navlinks {
          margin-top: 1rem;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        .external_link {
          margin-left: 0.5rem;
        }
      }

      .footer_Accordian {
        background-color: transparent;
        box-shadow: unset;
        border-radius: 0;

        &:before {
          background-color: unset;
        }

        &:global(.Mui-expanded) {
          margin: 0;
        }

        .footer_accordionSummary {
          min-height: unset;
          padding: 1rem 0;

          &:last-child {
            margin: 0;
          }

          :global(.MuiAccordionSummary-content) {
            margin: 0;
          }
        }

        .footer_accordianDetails {
          padding: 0;
        }
      }
    }
  }

  .footer_disclaimerDivider {
    margin: 1.5rem 0;
  }

  .footer_disclaimerbottomDivider {
    margin: 1.5rem 0;
  }

  .footer_copyright {
    p {
      margin: 0;
    }
  }
}

/*---------Below import needs to stays at bottom to overwrite css on mobile and tablet--------------*/
@import "../scss/responsive.scss";
/*---------Above import needs to stays at bottom to overwrite css on mobile and tablet--------------*/
