@import "../public/scss/mixins.scss";

/*----------Google map inner pin address css----------*/
.gm-style {
  .gm-style-iw-a {
    .gm-style-iw-t {
      .gm-style-iw-c {
        padding: 16px;
        border: 1px solid #e6e6e6;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        width: 100%;
        max-width: 246px !important;
        .gm-style-iw-d {
          padding: 0 0 5px;
          max-height: 100% !important;
        }
        .gm-ui-hover-effect {
          display: none !important;
        }
      }
    }
  }
  .gmnoprint {
    .gm-style-mtc {
      button {
        font-size: 14px !important;
      }
    }
  }
}
