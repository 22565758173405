@import "../scss/mixins.scss";

/*=======================================================CMS pages changes=================================================================*/

.CMSImage {
  position: relative;
  img {
    width: 100%;
    border-radius: 12px;
  }
}
.CmmunityImage {
  position: relative;
  :global(.MuiTypography-paragraph) {
    margin-bottom: 0;
  }
  :global(.wp-block-columns) {
    display: flex;
    gap: 24px;
    :global(.wp-block-column) {
      width: 100%;
      :global(.wp-block-image) {
        margin: 0;
        img {
          width: 100%;
          border-radius: 12px;
        }
      }
      :global(.wp-block-cover__inner-container) {
        position: absolute;
        background: rgba(255, 255, 255, 0.9);
        bottom: 24px;
        padding: 0.5rem 1rem;

        :global(.has-text-align-center) {
          margin: 0;

          a {
            font-size: 18px;
            font-weight: 600;
            color: $textPrimary;
            text-decoration: none;
          }
        }
      }
    }
  }
}

/*---------CMS contact us page----------*/

.helloDemo {
  .first_section {
    @include contactusField;
    :global(.MuiTypography-h6) {
      margin-top: 0;
    }
    .TextField {
      margin-bottom: 2rem;
      &.textarea {
        :global(.MuiInputBase-input) {
          padding: 0;
        }
      }
    }
    .formLabel {
      margin-bottom: 2rem;
    }
    .Last_input {
      &.textarea {
        :global(.MuiInputBase-input) {
          padding: 0;
        }
      }
    }
    .Capcha_field {
      margin-left: 0;
      padding: 12px;
      border: 1px solid $borderColor;
      border-radius: 0.5rem;
    }
  }

  .second_section {
    width: 100%;
    display: flex;
    gap: 1.5rem;
    flex-direction: column;

    :global(.inner-section) {
      border: 1px solid $borderColor;
      border-radius: 12px;
      padding: 1.5rem;
      width: 100%;
      :global(.wp-block-heading) {
        @include font3;
        margin: 0;
        margin-bottom: 5px;
      }
      :global(.has-link-color) {
        margin: 0;
        margin-bottom: 5px;

        a {
          @include font4;
        }
      }
      :global(.time) {
        @include flexcenter;
        margin: 0;
        @include font5;
        color: #5b5653;

        :global(.underline) {
          border-left: 1px solid $borderColor;
          margin: 0 0.5rem;
          height: 1rem;
        }
      }
      :global(.customerS) {
        @include fontweight5;
      }

      :global(.writeus-text) {
        margin: 0;
        @include fontweight5;
        color: #5b5653;
      }
    }
  }
}

/*----------community event-----------*/
.CommunityWrapper {
  .loginHeading {
    :global(.community-image) {
      max-width: 500px;
    }
  }

  .policyContent {
    margin-bottom: 1rem;
  }

  .listArticle {
    margin-top: 1rem;

    ul {
      margin: 0 0 0.5rem;

      li {
        font-weight: 600;
      }
    }
  }

  .policyContent {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
}

/*------------Request donation----------*/
.requestDonation {
  .policyContent {
    margin: 1rem 0;
  }

  .listArticle {
    ul {
      margin: 0;

      li {
        font-weight: 600;
      }
    }
  }
}

/*---------Care foundation------------*/
.careFoundation {
  .policyContent {
    margin: 1rem 0;
  }

  .loginHeading {
    :global(.wp-block-image) {
      margin: 0;

      img {
        margin: 0;
        border-radius: 0;

        &:last-child {
          margin-top: 1.5rem;
        }
      }
    }
  }
}

/*---------StoreTour-----------*/
.storeTour {
  :global(.wp-container-3) {
    display: flex;
    gap: 2rem;
    padding-bottom: 64px;
    border-bottom: 1px dashed $borderColor;
    margin-bottom: 40px;

    :global(.wp-block-column) {
      :global(.wp-block-image) {
        margin: 0;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  :global(.wp-block-heading) {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    margin: 0 0 20px;
  }

  :global(.wp-container-7) {
    display: flex;
    gap: 1.5rem;

    :global(.wp-block-column) {
      width: 100%;
      text-align: center;

      :global(.wp-block-image) {
        margin: 0;
      }

      p {
        @include font4;
        color: #565856;
      }
    }

    :global(.wp-block-image) {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  :global(.wp-container-10) {
    display: flex;
    margin-top: 5rem;
    margin-bottom: 2.5rem;

    :global(.wp-block-column) {
      width: 100%;

      :global(.has-background) {
        margin: 0;
        height: 100%;
        padding: 60px;
        @include flexcenter;
        justify-content: center;
        font-size: 24px;
        font-weight: 600;
        border-radius: 12px 0 0 12px;
      }

      :global(.wp-block-image) {
        margin: 0;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          border-radius: 0 12px 12px 0;
        }
      }
    }
  }

  :global(.wp-container-13) {
    display: flex;
    margin-bottom: 2.5rem;

    :global(.wp-block-column) {
      width: 100%;

      :global(.has-background) {
        margin: 0;
        height: 100%;
        padding: 60px;
        @include flexcenter;
        justify-content: center;
        font-size: 24px;
        font-weight: 600;
        border-radius: 0 12px 12px 0;
        color: $surfaceBG;
      }

      :global(.wp-block-image) {
        margin: 0;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          border-radius: 12px 0 0 12px;
        }
      }
    }
  }

  :global(.wp-container-16) {
    display: flex;
    margin-bottom: 2.5rem;

    :global(.wp-block-column) {
      width: 100%;

      :global(.has-background) {
        margin: 0;
        height: 100%;
        padding: 60px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 24px;
        font-weight: 600;
        border-radius: 12px 0 0 12px;

        :global(.has-inline-color) {
          margin-top: 1.5rem;
          @include font4;
        }
      }

      :global(.wp-block-image) {
        margin: 0;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          border-radius: 0 12px 12px 0;
        }
      }
    }
  }

  :global(.wp-block-group) {
    padding: 0 6rem;
    text-align: center;

    :global(.size-full) {
      display: flex;
      justify-content: center;
      margin: 0;
    }

    :global(.wp-block-heading) {
      margin-top: 1rem;
    }

    :global(.has-base-background-color) {
      margin: 0;
      @include font4;
      color: #565856;
      display: flex;
      flex-direction: column;

      :global(.has-inline-color) {
        margin-top: 1rem;
        // color: $ctaPrimary !important;
      }
    }
  }

  :global(.wp-block-contact-form-7-contact-form-selector) {
    margin-top: 2.5rem;
    padding: 0 6rem;

    :global(.wpcf7) {
      display: none;
    }
  }
  // am pm toggle button
  :global(.togglebuttons) {
    background-color: #eae8e3 !important;
    padding: 4px !important;
    display: flex;
  }

  :global(.togglebuttons) > span input {
    opacity: 0;
    position: absolute;
  }

  :global(.togglebuttons) > span :global(.wpcf7-list-item-label) {
    cursor: pointer;
    display: block;
    @include fontweight5;
    color: #5b5653;
    border-radius: 6px;
    background: $surfaceBG;
    padding: 8px 12px;
    background-color: transparent;
  }

  :global(.togglebuttons)
    > span
    input:checked
    + :global(.wpcf7-list-item-label) {
    background: $surfaceBG;
    border: 1px solid $surfaceBG;
    color: $textPrimary;
    font-weight: bold;
  }
  // am pm toggle button end
  :global(.submit-btn) {
    display: flex;
    justify-content: end;
    :global(.wpcf7-submit) {
      background-color: $ctaPrimary;
      border-radius: 8px;
      @include fontweight5;
      color: $surfaceBG;
      padding: 12px 1rem;
      border: unset;
      cursor: pointer;
    }
  }
}

/*--------------Career page---------------*/

.careerPage {
  .listArticle {
    ul {
      padding: 0;
      list-style: none;
      a {
        margin-bottom: 1rem;
        font-size: 15px;
        font-weight: 600;
        text-decoration: none;
      }
    }
  }
  :global(.wp-block-image) {
    margin: 0;
    margin-top: 1.5rem;
  }
  :global(.verify-image) {
    margin: 0;
    margin-top: 1.5rem;
  }

  :global(.wp-block-separator) {
    margin: 1.5rem 0;
  }

  :global(.about-usimg) {
    margin: 0;
    margin-top: 1rem;
    width: 100%;

    img {
      width: 100%;
    }
  }
}

/*-------------Shop now-------------*/
.shopNowwrapper {
  .shopNowimg {
    :global(.wp-block-image) {
      margin: 0;
      margin-bottom: 2.5rem;
      img {
        width: 100%;
      }
    }

    :global(.savemart-title) {
      margin: 0;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 0.5rem;
      text-align: center;
    }

    :global(.savemart-subtitle) {
      @include font6;
      margin: 0;
      text-align: center;
    }

    :global(.btn-icon) {
      @include flexcenter;
      justify-content: center;
      a {
        @include flexcenter;
        margin-top: 1.5rem;
        margin-bottom: 2.5rem;
        padding: 12px 1rem;
        border-radius: 8px;
        @include font7;
        text-decoration: none;
        img {
          margin-left: 14px;
        }
      }
    }

    :global(.wp-block-columns) {
      display: flex;
      width: 100%;
      gap: 1.5rem;

      :global(.wp-block-column) {
        width: 100%;

        &:global(.first-section) {
          padding-right: 1.5rem;
          border-right: 1px dashed $borderColor;

          :global(.wp-block-columns) {
            :global(.wp-block-column) {
              width: 100%;
            }
          }
        }

        :global(.wp-block-group) {
          @include flex;
          align-items: flex-start;
          gap: 2rem;
          padding: 1.5rem;
          border: 1px solid $borderColor;
          border-radius: 12px;
          margin-bottom: 1.5rem;

          :global(.wp-block-image) {
            margin: 0;
          }

          p {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            color: #5b5653;

            strong {
              @include font4;
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }
  .ShoppingTitle {
    :global(.shopping-instacart) {
      margin-bottom: 1.5rem;
      @include font4;
      margin-top: 0;
    }
  }
  .FAQ {
    :global(.wp-block-heading) {
      font-size: 24px;
      font-weight: 600;
      color: $textPrimary;
      margin: 0;
    }
  }
  .shopnow_accordion {
    .accordion_summary {
      :global(.MuiAccordionSummary-content) {
        :global(.MuiTypography-root) {
          :global(.wp-block-heading) {
            @include font4;
          }
        }
        &:global(.Mui-expanded) {
          :global(.MuiTypography-root) {
            :global(.wp-block-heading) {
              color: #5b5653;
            }
          }
        }
      }
    }
    :global(.gutena-accordion-block__panel-content-inner p) {
      margin: 0;
    }
  }
}
/*-------------MaxxSaving page css-------------*/
.maxxSaving {
  .shopNowimg {
    :global(.wp-block-image) {
      margin: 0;
      img {
        width: 100%;
      }
    }
    h4 {
      &:global(.has-text-align-center) {
        margin: 0;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0.5rem;
        text-align: center;
      }
    }
    p {
      &:global(.has-text-align-center) {
        @include font6;
        margin: 0;
        text-align: center;
        margin-bottom: 3rem;
      }
    }

    :global(.wp-block-columns) {
      :global(.wp-block-column) {
        p {
          @include font4;
          margin: 0;
          text-align: center;
        }
      }
    }
  }
  .ShoppingTitle {
    p {
      margin: 1rem 0;
      @include font4;
      text-align: center;
    }
  }
}
/*---------Recall information CMS page--------*/
.RecallInformation {
  .recallBody {
    :global(.entry-content) {
      p {
        font-size: 14px;
        font-weight: 600;
        color: #5b5653;
        margin: 0;
      }
    }
    :global(.wp-block-table) {
      margin: 0;
      margin-top: 1.5rem;
      overflow-x: auto;
      table {
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        thead {
          th {
            border-bottom: 1px solid $borderColor;
            text-align: left;
            padding: 0 0.5rem 0.5rem;
            strong {
              @include font8;
            }
          }
        }
        tbody {
          tr {
            &:hover {
              background-color: #f9f8f7;
            }
            td {
              padding: 0.5rem;
              p {
                strong {
                  @include font5;
                }
              }
            }
            :global(.ctm-font-size) {
              @include font5;
              p {
                font-size: unset;
                font-weight: unset;
                color: $textPrimary;
              }
            }
          }
        }
      }
    }
  }
}
/*-------------Peask season CMS---------*/
.peakSeason {
  :global(.alignfull) {
    position: relative;
    :global(.wp-block-cover__image-background) {
      width: 100%;
      height: unset;
      border-radius: 12px;
    }
    :global(.is-layout-constrained) {
      position: absolute;
      width: 100%;
      height: 98%;
      top: 0;
      right: 0;
      padding: 0 16px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        color: $surfaceBG;
        font-size: 24px;
        font-weight: 600;
        margin: 1rem 0 0;
        text-align: right;
      }
      :global(.is-content-justification-right) {
        display: flex;
        justify-content: end;
        margin-bottom: 1.5rem;
        :global(.wp-block-button__link) {
          background-color: $surfaceBG;
          border-radius: 0.5rem;
          padding: 0.5rem 2rem;
          cursor: pointer;
          font-weight: 600;
          font-size: 15px;
          text-decoration: none;
        }
      }
    }
  }

  :global(.wp-block-columns) {
    @include flex;
    border: 1px solid $borderColor;
    border-radius: 12px;
    :global(.wp-block-column) {
      width: 50%;
    }
    :global(.wp-block-image) {
      margin: 0;
      height: 100%;
      overflow: hidden;
      border-radius: 12px 0 0 12px;
      img {
        height: 100%;
        width: 100%;
      }
    }
    :global(.has-background) {
      padding: 4rem 5rem !important;
      border-radius: 0 12px 12px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      :global(.wp-block-heading) {
        font-size: 24px;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 1.5rem;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin: 0 !important;
      }
      :global(.wp-block-buttons) {
        margin-top: 1.5rem;
        :global(.wp-block-button__link) {
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
}
.feature2 {
  .peakSeason {
    :global(.alignwide) {
      :global(.has-text-color) {
        border-radius: 12px 0 0 12px;
        :global(.wp-block-heading),
        p {
          color: $textPrimary;
        }
      }
    }
    :global(.wp-block-columns .wp-block-image) {
      border-radius: 0 12px 12px 0;
    }
  }
}
.feature3 {
  :global(.wp-block-columns) {
    :global(.wp-block-column) {
      width: 50%;
      p {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 1.5rem;
      }
    }
    :global(.wp-block-image) {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: auto;
        width: 100%;
        max-width: 500px;
      }
    }
    :global(.wp-block-buttons) {
      :global(.wp-block-button__link) {
        font-size: 18px;
        font-weight: 600;
        color: $surfaceBG;
        cursor: pointer;
      }
    }
  }
}
.feature4 {
  :global(.wp-block-heading) {
    font-size: 32px;
    margin: 0 0 24px;
  }
  p {
    font-size: 16px;
    margin: 0;
  }
}
.feature5 {
  :global(.wp-block-columns) {
    :global(.has-base-background-color) {
      ul {
        padding-left: 26px;
        margin-top: 0 !important;
        li {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
}
/*--------Features page-----------*/
.featureCMS {
  text-align: center;
  :global(.wp-block-image) {
    margin: 0;
    img {
      width: 100%;
    }
    margin-bottom: 3rem;
  }
  .policyContent {
    margin-bottom: 1.5rem;
    font-size: 30px;
    font-weight: 800;
    text-transform: capitalize;
    color: #48675c;
    font-weight: 400;
    line-height: 35px;
    strong {
      font-weight: 800;
    }
  }

  .titleheading {
    margin-bottom: 1.5rem;
    font-size: 30px;
    font-weight: 800;
    text-transform: capitalize;
    color: #48675c;
    font-weight: 400;
    line-height: 35px;
    strong {
      font-weight: 800;
    }
    &:nth-child(3n) {
      font-size: 20px;
    }
  }
  .belowSavesection {
    .policyContent {
      width: 50%;
      font-size: 16px;
      line-height: unset;
      margin-bottom: 0;
    }
    :global(.MuiStack-root) {
      width: 50%;
    }
  }
}

/*--------Best of fresh page-----------*/
.bestoffresh {
  .otherImages {
    @include flexcenter;
    :global(.wp-block-image) {
      margin: 0;
      width: 100%;
      img {
        width: 100%;
      }
      margin-bottom: 1.5rem;
    }
  }
  .socialM {
    :global(.is-vertically-aligned-top) {
      text-align: center;
      p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
      }
    }
    :global(.is-vertically-aligned-center) {
      @include flexcenter;
      justify-content: center;
      align-items: flex-start;
      :global(.wp-block-image) {
        max-width: 20px;
        height: 20px;
        margin-right: 16px;
      }
      p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        a {
          color: #5b5653;
        }
      }
    }
  }
}
/*---------------chase the saving-----------------*/
.ChaseTheSavings {
  .sepratBlock {
    margin: 1rem 0;
    :global(.wp-block-separator) {
      height: 4px;
    }
    :global(.wp-block-cover) {
      @include flexcenter;
      justify-content: center;
      background-color: #f6f6f6;
      :global(.wp-block-button__link) {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
  .paringBlock {
    .otherImages {
      :global(.wp-block-image) {
        margin: 0;
      }
    }
    .sepratBlock {
      margin: 0;
      text-align: center;
    }
  }
}
/*---------------AppDownload---------------*/
.AppDownload {
  .sepratBlock {
    :global(.wp-block-cover) {
      :global(.wp-block-cover__inner-container) {
        padding: 1.5rem;
        background-position: bottom;
        background-image: url(https://savemart.cms.sandbox.ps.swiftlycontent.net/wp-content/uploads/sites/5/2023/05/imgpsh_fullsize_anim.jpg);
        background-repeat: no-repeat;
        text-align: center;
        :global(.wp-block-columns) {
          @include flex;
          gap: 1.5rem;
          :global(.wp-block-column) {
            width: 50%;
            h1 {
              font-size: 48px;
              margin: 0;
            }
            h2 {
              font-size: 28px;
              color: $surfaceBG;
              font-weight: 500;
            }
            p {
              font-size: 20px;
              color: $surfaceBG;
              &:nth-child(2n) {
                font-size: 16px;
                margin-bottom: 1rem;
              }
            }
            :global(.are-vertically-aligned-center) {
              gap: 0.5rem;
              :global(.wp-block-image) {
                margin: 0;
                img {
                  width: 100%;
                }
              }
            }
            :global(.size-full) {
              margin: 0;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .paringBlock {
    .socialM {
      :global(.wp-block-column) {
        h2 {
          margin: 0;
          margin-bottom: 16px;
          font-size: 32px;
        }
        p {
          font-size: 16px;
          a {
            color: $ctaPrimary;
          }
        }
      }
    }
  }
  .shopnow_accordion {
    .accordion_summary {
      :global(.MuiAccordionSummary-content) {
        :global(.MuiTypography-root) {
          :global(.wp-block-heading) {
            @include font4;
          }
        }
        &:global(.Mui-expanded) {
          :global(.MuiTypography-root) {
            :global(.wp-block-heading) {
              color: #5b5653;
            }
          }
        }
      }
    }
    :global(.gutena-accordion-block__panel-content-inner p) {
      margin: 0;
    }
  }
}
/*----------Gallery Wrapper-----------*/
.GasRewards {
  .galleryWrapper {
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
    gap: 16px;
    .otherImages {
      :global(.wp-block-image) {
        margin: 0;
      }
    }
  }
  :global(.wp-block-separator) {
    height: 25px;
    margin-bottom: 3rem;
  }
  .policyContent {
    font-size: 14px;
  }
}

/*---------Below import needs to stays at bottom to overwrite css on mobile and ipad--------------*/
@import "../scss/responsive.scss";
/*---------Above import needs to stays at bottom to overwrite css on mobile and ipad--------------*/
