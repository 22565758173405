.SwipeableTextMobileStepper-pagingDots {
  --color: #000000;
  opacity: 1 !important;
  top: 2px;
  position: relative;
}

.SwipeableTextMobileStepper-pagingDots svg {
  border-radius: 100px;
  stroke: var(--color) !important;
  fill: white !important;
  height: 12px;
  width: 12px;
}

.SwipeableTextMobileStepper-pagingDots[aria-selected="true"] svg {
  fill: var(--color) !important;
}

.SwipeableTextMobileStepper-container.display-none ul {
  display: none !important;
}

.SwipeableTextMobileStepper-container ul {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 100px;
  padding: 6px 8px !important;
}

.SwipeableTextMobileStepper-carousel-button {
  cursor: pointer;
  --dark-bg: #000000;
}

.SwipeableTextMobileStepper-carousel-button:disabled {
  cursor: not-allowed;
  --dark-bg: #000000;
}

.SwipeableTextMobileStepper-carousel-button:hover:not([disabled]) {
  background: var(--dark-bg) !important;
}
/* To fix the dynamic height of the carousel */
.slider-container {
  display: flex;
}
.slider-container div {
  flex: none !important;
}
.slider-container .slider-list div {
  height: 100%;
}
