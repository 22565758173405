@import "../scss/mixins.scss";

/*------------Responsive CSS Start--------------*/

@media only screen and (min-width: 1200px) {
  /*-------instacart banner css below--------*/
  .peakSeason {
    :global(.alignfull) {
      :global(.is-layout-constrained) {
        p {
          padding-left: 48rem;
        }
      }
    }
  }
  /*-------Store tour css below--------*/
  .storetour {
    .StoreTourContact {
      padding: 0 10rem;
    }
  }
}
@media only screen and (min-width: 1440px) {
  /*-------instacart banner css below--------*/
  .peakSeason {
    :global(.alignfull) {
      :global(.is-layout-constrained) {
        p {
          padding-left: 59rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 800px) and (max-width: 1199px) {
  .peakSeason {
    :global(.wp-block-columns) {
      :global(.has-background) {
        padding: 2rem !important;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  /*-------Footer css below--------*/
  .footer_Wrapper {
    .footer_box {
      .footer_Right {
        .footer_responsiveBorder {
          display: none;
        }
      }
    }
  }
  /*-------Peak season css below--------*/
  .peakSeason {
    :global(.alignfull) {
      :global(.wp-block-cover__image-background) {
        min-height: 200px;
        object-fit: cover;
        object-position: left;
      }
    }
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  /*-------Store tour css below--------*/
  .storetour {
    .StoreTourContact {
      padding: 0 6rem;
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /*-------instacart banner css below--------*/
  .peakSeason {
    :global(.alignfull) {
      :global(.is-layout-constrained) {
        p {
          padding-left: 32rem;
        }
      }
    }
  }
  /*-------Coupons and product card css below--------*/
  .couponsC,
  .productC {
    :global(.sk-CouponCard),
    :global(.sk-ProductCard) {
      min-width: 163px;
      max-width: 163px;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  /*-------instacart banner css below--------*/
  .peakSeason {
    :global(.alignfull) {
      :global(.is-layout-constrained) {
        p {
          padding-left: 15rem;
        }
      }
    }
  }
  /*-------Coupons and product card css below--------*/
  .couponsC,
  .productC {
    :global(.sk-CouponCard),
    :global(.sk-ProductCard) {
      min-width: 168px;
      max-width: 168px;
    }
  }

  /*-------Shop now css below--------*/

  .shopNowwrapper {
    .shopNowimg {
      :global(.wp-block-columns) {
        :global(.wp-block-column) {
          width: 100%;
          display: flex;
          gap: 1rem;
          &:global(.first-section) {
            :global(.wp-block-columns) {
              :global(.wp-block-column) {
                display: flex;
                gap: 1rem;
              }
            }
          }

          :global(.wp-block-group) {
            flex-direction: column;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .couponListConatiner {
    padding-top: 0px;
  }
  /*-------Footer css below--------*/
  .footer_Wrapper {
    .footer_box {
      .footer_Left {
        .footer_socialIcons {
          margin: 0;
        }

        &.marginLeft {
          padding: 0;
          margin-top: 1.5rem;
          margin-right: 0;
          padding-top: 1.5rem;

          .iSection_justify {
            @include flexjustify;
            margin-bottom: 1.5rem;
            padding-bottom: 1.5rem;
          }

          .DownloadApp {
            text-align: center;
            display: block;

            .footer_DownloadTitle {
              margin-bottom: 0.5rem;
              margin-top: 0;
            }

            .footer_appLinks {
              margin-right: 1rem;

              &:last-child {
                margin: 0;
              }
            }
          }

          &.mobileLeft {
            margin-top: 0;
            border: 0;
            padding: 0;
          }
        }
      }

      .footer_Right {
        .marginBox {
          margin-right: 40px;
          &.lastDiffe {
            margin-right: 100px;
          }
        }

        &.mobileNavright {
          .footer_Links {
            margin-bottom: 1rem;
            .footer_navlinks {
              padding: 0.5rem 1rem;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .helloDemo {
    flex-direction: row;
  }
}

@media only screen and (max-width: 992px) {
  /*-------Store tour css below--------*/
  .storeTour {
    :global(.wp-container-10),
    :global(.wp-container-16) {
      flex-direction: column-reverse;

      :global(.wp-block-column) {
        :global(.has-background) {
          border-radius: 0 0 12px 12px;
        }

        :global(.wp-block-image img) {
          border-radius: 12px 12px 0 0;
        }
      }
    }

    :global(.wp-container-13) {
      flex-direction: column;

      :global(.wp-block-column) {
        :global(.has-background) {
          border-radius: 0 0 12px 12px;
        }

        :global(.wp-block-image img) {
          border-radius: 12px 12px 0 0;
        }
      }
    }

    :global(.wp-block-group),
    :global(.wp-block-contact-form-7-contact-form-selector) {
      padding: 0;
    }
  }

  /*-------Shop now css below--------*/

  .shopNowwrapper {
    .shopNowimg {
      :global(.wp-block-columns) {
        :global(.wp-block-column) {
          width: 100%;

          :global(.shopping-instacart) {
            text-align: center;
          }

          &:global(.first-section) {
            padding-right: 0;
            padding-bottom: 1.5rem;
            border-right: unset;
            border-bottom: 1px dashed $borderColor;
          }

          :global(.wp-block-group) {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
  /*-------MaxxSaving css below--------*/
  .maxxSaving {
    .shopNowimg {
      :global(.wp-block-columns) {
        :global(.wp-block-column) {
          flex-direction: column;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .footer_Wrapper {
    .footer_contactus {
      text-align: center;

      .footer_contactusbtn {
        width: 100%;
        margin-top: 1rem;
        padding: 12px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  /*-------Community page css below--------*/
  .CmmunityImage {
    :global(.wp-block-columns) {
      &:nth-child(2n) {
        margin-top: 24px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  /*-------peak season cms ----------*/
  .peakSeason {
    :global(.wp-block-columns) {
      flex-direction: column;
      :global(.wp-block-column) {
        width: 100%;
        :global(.wp-block-image) {
          border-radius: 12px 12px 0 0;
          img {
            height: auto;
          }
        }
      }
      :global(.has-background) {
        padding: 2rem !important;
        border-radius: 0 0 12px 12px;
      }
    }
  }
  .feature2 {
    .peakSeason {
      :global(.alignwide) {
        :global(.has-text-color) {
          border-radius: 0 0 12px 12px;
        }
      }
      :global(.wp-block-columns) {
        flex-direction: column-reverse;
        :global(.wp-block-image) {
          border-radius: 12px 12px 0 0;
        }
      }
    }
  }
  .feature3 {
    :global(.wp-block-columns) {
      :global(.wp-block-column) {
        p {
          font-size: 16px;
        }
      }
    }
  }
  .feature4 {
    :global(.wp-block-heading) {
      font-size: 28px;
    }
  }
}
@media only screen and (max-width: 767px) {
  /*-------peak season cms ----------*/
  .peakSeason {
    :global(.wp-block-columns) {
      :global(.has-background) {
        padding: 1rem !important;
      }
    }

    :global(.alignfull) {
      :global(.is-layout-constrained) {
        padding: 0 15px;
        :global(.is-content-justification-right) {
          flex-direction: row;
        }
        p {
          font-size: 18px;
          font-weight: 500;
        }
      }
      :global(.wp-block-cover__image-background) {
        min-height: 180px;
        object-fit: cover;
        object-position: left;
      }
    }
  }

  /*-------Coupons and product card css below--------*/
  .couponsC,
  .productC {
    :global(.sk-CouponCard),
    :global(.sk-ProductCard) {
      min-width: 156px;
      max-width: 156px;
    }
  }
  /*-------Community page css below--------*/
  .CmmunityImage {
    :global(.MuiTypography-paragraph) {
      margin-bottom: 24px;
    }
    :global(.wp-block-columns) {
      flex-direction: column;
      gap: 0;
      :global(.wp-block-column) {
        margin-bottom: 24px;
      }
    }
  }
  /*-------Contact us css below--------*/
  .helloDemo {
    .first_section {
      padding: 1rem;
      .TextField {
        margin-bottom: 1.5rem;
      }
      &.storeSection {
        padding: 0;
        border: unset;
      }
    }
    .second_section {
      gap: 1rem;
    }
  }

  /*-------Shop now css below--------*/
  .shopNowwrapper {
    .shopNowimg {
      :global(.wp-block-image) {
        margin-bottom: 1rem;
      }

      :global(.savemart-title) {
        font-size: 20px;
      }

      :global(.wp-block-buttons) {
        margin-bottom: 1rem;
      }

      :global(.wp-block-columns) {
        :global(.wp-block-column) {
          :global(.shopping-instacart) {
            margin-bottom: 1rem;
          }

          :global(.wp-block-group) {
            :global(.wp-block-image img) {
              width: 50px;
            }
          }
        }
      }
    }
  }

  .headerWrapper {
    .toolbar {
      min-height: 56px;
    }
  }
  /*-------Stour tour css below--------*/
  .storeTour {
    :global(.wp-container-3) {
      flex-direction: column;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }

    :global(.wp-block-heading) {
      font-size: 24px;
      font-weight: 600;
    }

    :global(.wp-container-7) {
      flex-direction: column;
    }

    :global(.wp-container-10),
    :global(.wp-container-13),
    :global(.wp-container-16) {
      margin-bottom: 1rem;

      :global(.wp-block-column) {
        :global(.has-background) {
          font-size: 18px;
          padding: 2.5rem 2rem;
        }
      }
    }

    :global(.wp-block-contact-form-7-contact-form-selector) {
      :global(.wpcf7) {
        border: unset;
        padding: 0;

        :global(.wpcf7-form) {
          :global(.form-Wrapper) {
            :global(.sendmessage),
            :global(.allField) {
              text-align: center;
            }

            :global(.main-div) {
              flex-direction: column;
            }

            :global(.submit-btn) {
              display: block;

              :global(.wpcf7-submit) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  /*----contact us css-------*/
  .helloDemo {
    gap: 1rem;

    :global(.first-section) {
      padding: 1rem;

      :global(.wpcf7-form) {
        :global(.form-Wrapper) {
          :global(.main-div) {
            flex-direction: column;
          }
        }
      }
    }

    :global(.second-section) {
      gap: 1rem;

      :global(.inner-section) {
        padding: 1rem;
      }
    }
  }

  /*--------Coupons account code----------*/
  .AccountMain_section {
    .mySection {
      padding: 0 0 1rem 0;
    }

    .mycouponsBody {
      padding: 1rem 0 0;

      .tabsSection {
        margin-bottom: 1rem;
      }
    }
  }

  /*-------Store locator css below--------*/
  .storeinfoMain {
    .CardContent {
      &:last-child {
        padding-bottom: 1rem;
      }
    }
  }

  /*-------Footer css below--------*/

  .footer_Wrapper {
    padding: 1rem 0;

    .footer_topBorder {
      margin-top: 1rem;
    }

    .footer_box {
      .footer_Left {
        .footer_mobileResponsiveblock {
          @include flexjustify;
          flex-direction: row;

          .footer_socialIcons {
            margin: 0;
          }
        }

        .footer_DownloadTitle {
          margin-top: 1rem;
        }

        .footer_appLinks {
          margin-top: 0;
          margin-right: 1rem;
        }

        .footer_appstoreResponsive {
          flex-direction: row;
          justify-content: center;

          .footer_appStore {
            margin-right: 1rem;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .footer_Right {
        padding-top: 1rem;
      }
    }

    .footer_disclaimerbottomDivider {
      margin: 1rem 0;
    }
  }

  .footer_Wrapper {
    .footer_contactus {
      .contactBody {
        text-align: center;

        :global(.wp-block-columns) {
          flex-direction: column;

          :global(.wp-block-buttons) {
            padding: 12px;
            margin-top: 1rem;
            max-width: unset;
          }
        }

        :global(.wp-block-column) {
          &:nth-child(2n) {
            width: 100%;
          }
        }
      }
    }
  }

  .couponListConatiner {
    .bannerTopSection {
      :global(.slider-container) {
        :global(.slider-control-bottomcenter) {
          ul {
            display: none !important;
          }
        }
      }
    }
  }
  .policyContent {
    word-break: break-word;
  }
  /*--------Features page-----------*/
  .featureCMS {
    .policyContent {
      font-size: 20px;
      line-height: 24px;
    }
    .titleheading {
      font-size: 20px;
      line-height: 24px;
    }
    .belowSavesection {
      flex-direction: column-reverse;
      .policyContent,
      :global(.MuiStack-root) {
        width: 100%;
        font-size: 14px;
      }
    }
  }
  /*--------chase the saving page----------*/
  .ChaseTheSavings {
    .paringBlock {
      .otherImages {
        :global(.wp-block-image) {
          margin: 1.5rem 0;
        }
      }
    }
  }
  /*---------------AppDownload---------------*/
  .AppDownload {
    .sepratBlock {
      :global(.wp-block-cover) {
        :global(.wp-block-cover__inner-container) {
          padding: 0.5rem;
          background-position: top;
          :global(.wp-block-columns) {
            flex-direction: column-reverse;
            :global(.wp-block-column) {
              width: 100%;
              h1 {
                font-size: 28px;
              }
              h2 {
                font-size: 22px;
              }
              p {
                font-size: 18px;
              }
              :global(.are-vertically-aligned-center) {
                flex-direction: row;
              }
              :global(.size-full) {
                img {
                  width: 100%;
                  max-width: 200px;
                }
              }
            }
          }
        }
      }
    }
    .paringBlock {
      .socialM {
        :global(.wp-block-column) {
          h2 {
            font-size: 24px;
          }
        }
      }
    }
  }
}
