@import "../scss/mixins.scss";
@import "../scss/variables.scss";

/*--------search place input custom popper--------*/

.customPopper {
  box-shadow: unset;
  background: unset;
  :global(.MuiPaper-root) {
    box-shadow: unset;
    margin-top: 2px;
    border: 1px solid $borderColor;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 0;
    font-family: "Open Sans", sans-serif !important;
    .customPopperListbox {
      font-size: 16px;
      font-weight: 600;
      padding: 1rem;
      .customPopperListboxOptions {
        padding: 0;
        margin: 0;
      }
    }
  }
}

.storeSearchInput {
  @include storeSearchInput;
  .iconLocate {
    padding: 5px;
  }
}

/*---------Below import needs to stays at bottom to overwrite css on mobile and ipad--------------*/
@import "../scss/responsive.scss";
/*---------Above import needs to stays at bottom to overwrite css on mobile and ipad--------------*/
