@import "../scss/mixins.scss";
@import "../scss/variables.scss";

.cursor {
  cursor: pointer;
}
/*------Common cms changes--------*/
.rightBox {
  .loginHeading {
    text-align: left;
    :global(.wp-block-heading) {
      margin: 0 0 1rem;
      strong {
        @include font4;
      }
    }
  }

  :global(.MuiTypography-root) {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
  }

  .listArticle {
    ul {
      li {
        font-size: 14px;
        color: $textPrimary;
      }
    }
  }
}

.listItems {
  padding-left: 24px;
  margin: 8px 0px 0px;
}

/*---Cms css----*/
.rightSection {
  .policyContent {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .listArticle {
    ul {
      padding-left: 24px;

      li {
        font-size: 14px;
        font-weight: 400;
        font-family: "Open Sans", sans-serif;
      }
    }
  }
}

.policyContent {
  a {
    color: $textPrimary;
  }
}

.loginHeading {
  text-align: center;

  p {
    margin: 0px 0px 16px;
    font-size: 18px;
    font-weight: 600;

    a {
      color: $textPrimary;
    }
  }

  img {
    @include cmsImg;
    margin: 24px 0px;
  }
}

.listArticle {
  ul {
    margin: 8px 0px;

    li {
      font-size: 16px;
      font-weight: 400;
      font-family: "Open Sans", sans-serif;
    }
  }
}

.imageSave {
  text-align: center;

  .saveMartimg {
    @include cmsImg;
  }
}

@import "../scss/responsive.scss";
