@import "../scss/mixins.scss";

/*------coupons list global-------*/
.couponsC {
  :global(.sk-CouponList) {
    margin: 0;
    margin-top: 2rem;

    :global(.MuiTypography-h5),
    :global(.sk-CouponList__title) {
      margin: 0;
      @include fontweight6;
      font-size: 24px;
    }

    :global(.sk-CouponList__container) {
      flex-direction: row;
      margin-top: 1rem;

      ul {
        padding-left: 0;
      }
      @media only screen and (max-width: 1199px) {
          ul {
            scroll-snap-align: inherit;
            -webkit-box-pack: inherit;
          }
      }

      :global(.sk-CouponList__list-container) {
        padding-right: 24px;
        gap: 24px;

        :global(.sk-CouponCard) {
          @include couponsCardinner;
        }

        :global(.sk-CouponCard__divider) {
          @include couponsDividerinner;
        }

        :global(.sk-CouponCard__bottom) {
          @include couponsBottombtn;
        }
      }
    }
  }
}

/*--------Coupons grid global---------*/
.couponsC {
  :global(.sk-CouponCard) {
    @include couponsCardinner;

    :global(.sk-CouponCard__divider) {
      @include couponsDividerinner;
    }

    :global(.sk-CouponCard__bottom) {
      @include couponsBottombtn;
    }
  }
}

/*----------Product card---------------*/
.productC {
  :global(.sk-ProductList__title) {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
  }

  :global(.sk-ProductCard) {
    border-color: $borderColor;
    border-width: 1px;
    width: 100%;
    min-width: 180px;
    max-width: 180px;
    height: 248px;

    > :global(*) {
      width: 100%;

      > :global(*) {
        width: 100%;
      }
    }

    :global(.sk-ProductCard__media) {
      padding: 1rem;
      padding-bottom: 0;
    }

    :global(.sk-ProductCard__content) {
      padding-bottom: 8px;


      :global(.sk-ProductCard__base_price) {
        margin: 0;
      }

      :global(.MuiTypography-h6) {
        padding-bottom: 2px;
        @include fontweight5;
        margin-bottom: 5px;
      }

      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 1rem;
      }

      :global(.sk-ProductCard__price) {
        margin-top: 1rem;
        margin-bottom: 0;
      }

      :global(.sk-ProductCard__description) {
        margin-bottom: 6px;
      }

      :global(.sk-ProductCard__content_div) {
        :global(.sk-ProductCard__sale) p {
          font-size: 17px;
          font-weight: 700;
          color: white;
        }

        :global(.sk-ProductCard_error_price) {
          color: #837E75
        }

        :global(.sk-ProductCard__base_price) {
          margin: 0;
          font-size: 17px;
          color: initial;
        }

        :global(.sk-ProductCard__promo_price) {
          margin: 0;
          font-size: 17px;
          //color: #005E8F;
        }
      }

    }

    :global(.MuiButton-contained:not(:hover)) {
      background-color: $surfaceBG,
    }
  }
}

/*---------Below import needs to stays at bottom to overwrite css on mobile and ipad--------------*/
@import "../scss/responsive.scss";
/*---------Above import needs to stays at bottom to overwrite css on mobile and ipad--------------*/
