@import "../scss/mixins.scss";

.headerWrapper {
  .wrapperH {
    .logo_link {
      margin-right: 2rem;
      @include flexcenter;
    }
  }
}

/*----------Breadcrumb css start-------------*/
.breadcrumbMain {
  :global(.MuiBreadcrumbs-ol) {
    .bread_deactive {
      :global(.MuiTypography-root) {
        color: #626262;
        opacity: 1;
      }
    }
  }
}

/*---------Customscroll-------------*/
@media only screen and (min-width: 1199px) {
  .customScroll::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 1rem;
    &:hover {
      width: 13px;
    }
  }

  /* Track */

  .customScroll::-webkit-scrollbar-track {
    background: #f0f3f4;
    border-radius: 1rem;
  }

  /* Handle */

  .customScroll::-webkit-scrollbar-thumb {
    background: #dadada;
    border-radius: 1rem;
    box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
      inset -2px -2px 2px rgba(0, 0, 0, 0);
  }

  /* Handle on hover */

  .customScroll::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
}
/*---------Below import needs to stays at bottom to overwrite css on mobile and ipad--------------*/
@import "../scss/responsive.scss";
/*---------Above import needs to stays at bottom to overwrite css on mobile and ipad--------------*/
