@import "../scss/variables.scss";

/*--------------Flex content-----------------*/
@mixin flex {
  display: flex;
}

@mixin flexcenter {
  @include flex;
  align-items: center;
}

@mixin flexjustify {
  @include flexcenter;
  justify-content: space-between;
}

/*--------------fonts-----------------*/
@mixin font1 {
  font-size: 15px;
  //color: $textPrimary;
}

@mixin font2 {
  font-weight: 500;
  font-size: 16px;
  color: $ctaPrimary;
}

@mixin font3 {
  font-size: 18px;
  color: $textPrimary;
}

@mixin font4 {
  font-weight: 600;
  font-size: 18px;
  color: $textPrimary;
}

@mixin font5 {
  font-weight: 400;
  font-size: 14px;
  color: $textPrimary;
}

@mixin font6 {
  font-weight: 600;
  font-size: 16px;
  color: $textPrimary;
}

@mixin font7 {
  font-weight: 600;
  font-size: 15px;
  color: #3e4933;
}

@mixin font8 {
  font-size: 14px;
  color: $textPrimary;
  font-weight: 600;
}

@mixin fontweight5 {
  @include font1;
  font-weight: 600;
}

@mixin fontweight6 {
  font-weight: 600;
  color: $textPrimary;
}

@mixin fontweight7 {
  font-size: 13px;
  font-weight: 400;
  color: #5B5653;
}

/*-----Store locator search input--------*/
@mixin storeSearchInput {
  :global(.MuiInputBase-root) {
    border-radius: 12px;
    background: $surfaceBG;
    padding: 8px;

    :global(.MuiInputBase-input) {
      font-size: 16px;
      font-weight: 400;
      padding: 0;
    }

    :global(.MuiAutocomplete-endAdornment) {
      display: none;
    }

    :global(.MuiOutlinedInput-notchedOutline) {
      border-color: $borderColor;
    }

    &:global(.Mui-focused) {
      :global(.MuiOutlinedInput-notchedOutline) {
        border: 1px solid $textPrimary;
        border-radius: 8px;
      }
    }
  }
}

/*----------mixin for common coupons------------*/
@mixin couponsCardinner {
  margin: 0;
  border: 1px solid $borderColor;
  margin-left: 0;
  width: 100%;
  min-width: 180px;
  max-width: 180px;

  :global(.sk-CouponCard__card-container) {
    padding: 0;

    :global(.sk-CouponCard__brand) {
      font-size: 18px;
      margin: 5px 0;
      width: 100%;
      font-weight: 600;
    }

    :global(.MuiTypography-h3) {
      font-size: 18px;
      width: 100%;
      margin: 0;
      font-weight: 600;
    }

    :global(.sk-CouponCard__value) {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
      width: 100%;
    }

    :global(.sk-CouponCard__description) {
      @include fontweight7;
      color: #5b5653;
    }

    &:focus {
      border-radius: 8px;
    }
  }
}

@mixin couponsDividerinner {
  :global(.sk-CouponCard__divider-line),
  :global(.sk-CouponCard__circle-left),
  :global(.sk-CouponCard__circle-right) {
    border-width: 1px;
    border-color: $borderColor;
  }

  :global(.sk-CouponCard__circle-left) {
    margin-left: -1px;
    border-left-color: $surfaceBG;
    background-color: $surfaceBG;
  }

  :global(.sk-CouponCard__circle-right) {
    margin-right: -1px;
    border-right-color: $surfaceBG;
    background-color: $surfaceBG;
  }
}

@mixin couponsBottombtn {
  padding: 0;
  margin: 1rem 0;

  :global(.sk-CouponCard__bottom-text) {
    :global(.MuiTypography-root) {
      @include fontweight7;
      font-weight: 600;
    }
  }

  :global(svg) {
    width: 27px;
  }
}

@mixin cmsImg {
  margin-top: 8px;
  width: 100%;
  max-width: 1100px;
  border-radius: 24px;
}

/*--------contact us form cms page mixin below------------*/

@mixin contactusField {
  border: 1px solid $borderColor;
  border-radius: 12px;
  padding: 1.5rem;
  width: 100%;

  :global(.col-1),
  :global(.col-2) {
    width: 100%;
  }

  p {
    :global(.wpcf7-form-control-wrap) {
      margin-top: 5px;

      :global(.wpcf7-form-control) {
        border: 1px solid $borderColor;
        border-radius: 0.5rem;
        padding: 14px 16px;
      }
    }
  }

  :global(.wpcf7-form) {
    :global(.form-Wrapper) {
      :global(.sendmessage) {
        @include font4;
        margin-bottom: 5px;
      }

      :global(.allField) {
        @include font5;
        margin-bottom: 20px;
      }

      :global(.main-div) {
        display: flex;
        gap: 16px;
        margin-bottom: 20px;

        p {
          margin: 0;

          :global(.label-text) {
            display: flex;
            flex-direction: column;
            font-weight: 600;
            font-size: 13px;
            color: $textPrimary;
            margin-bottom: 5px;
            margin-left: 16px;
          }

          :global(.wpcf7-form-control-wrap) {
            margin-top: 5px;

            :global(.wpcf7-form-control) {
              border: 1px solid $borderColor;
              border-radius: 0.5rem;
              padding: 14px 16px;
              width: 100%;
              font-family: "Open Sans", sans-serif;
              @include font5;
            }

            :global(.wpcf7-textarea) {
              resize: none;
              font-family: "Open Sans", sans-serif;
              @include font5;
              height: 120px;
            }
          }
        }

        &:global(.feedback) {
          flex-direction: column;
          gap: 0;
          margin-bottom: 2rem;

          :global(.feedback-msg) {
            margin-left: 16px;
            margin-top: 5px;
            font-size: 13px;
            font-weight: 400;
            color: #5b5653;
          }
        }

        :global(.dateSection),
        :global(.timeSection) {
          p {
            @include flex;
            gap: 0.5rem;
          }
        }
      }

      :global(.optional-area) {
        :global(.wpcf7-textarea) {
          height: 72px !important;
        }
      }

      :global(.additional-detail) {
        @include flexcenter;
        margin-left: 1rem;

        :global(.field) {
          margin-right: 0.5rem;
        }

        :global(.provide-Detail) {
          :global(.wpcf7-form-control) {
            background-color: unset;
            border: unset;
            padding: 0;

            label {
              @include flexcenter;
              cursor: pointer;

              input {
                cursor: pointer;
              }

              span {
                @include font5;
                margin-left: 0.5rem;
              }
            }
          }
        }
      }

      :global(.last-Submit) {
        @include flexjustify;
        border-top: 1px dashed $borderColor;
        margin-top: 2rem;
        padding-top: 1.5rem;

        :global(.col-1) {
          width: unset;

          p {
            border: 1px solid $borderColor;
            padding: 12px 1rem;
            border-radius: 0.5rem;
            margin: 0;

            :global(.wpcf7-form-control-wrap) {
              :global(.wpcf7-form-control) {
                background-color: unset;
                border: unset;
                padding: 0;

                label {
                  cursor: pointer;
                  @include flexcenter;

                  input {
                    cursor: pointer;
                  }

                  span {
                    @include font5;
                    margin-left: 0.5rem;
                  }
                }
              }
            }
          }
        }

        :global(.submit-btn) {
          display: flex;
          justify-content: end;

          :global(.wpcf7-submit) {
            background-color: $ctaPrimary;
            border-radius: 8px;
            @include fontweight5;
            color: $surfaceBG;
            padding: 12px 1rem;
            border: unset;
            cursor: pointer;
          }

          p {
            margin: 0;
          }
        }
      }
    }
  }
}
