@import "../scss/mixins.scss";

.formSect {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover {
    font-size: 14px;
  }
}

/*===============Coupons and top section carousel changes================*/
.couponsC,
.bannerTopSection {
  :global(.slider-container) {
    :global(.slide) {
      padding: 0 !important;
    }

    :global(.slider-control-bottomcenter) {
      ul {
        top: -20px !important;

        li {
          display: flex;
        }
      }
    }

    :global(.slider-control-centerright) {
      :global(.SwipeableTextMobileStepper-carousel-button) {
        margin-right: 0 !important;
        right: 16px !important;
        bottom: 16px !important;
      }
    }

    :global(.slider-control-centerleft) {
      :global(.SwipeableTextMobileStepper-carousel-button) {
        margin-right: 48px !important;
        bottom: 16px !important;
      }
    }
  }
}

.tablePaginationSelect {
  display: none;
}

/*===============Product detail modal ================*/

.productModal {
  :global(.MuiTypography-h1) {
    @include fontweight6;
    font-size: 1rem;
    line-height: 1.25rem;
  }
  :global(.MuiTypography-h2) {
    @include fontweight6;
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}

@media only screen and (max-width: 768px) {
  .productModal {
    :global(.MuiTypography-h2) {
      @include fontweight6;
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }
}

/*---------Below import needs to stays at bottom to overwrite css on mobile and ipad--------------*/
@import "../scss/responsive.scss";
/*---------Above import needs to stays at bottom to overwrite css on mobile and ipad--------------*/
